<template>
  <v-dialog persistent
    v-model="activeModal"
    max-width=1500
    class="modal-cadastro"
  >
    <template v-slot:activator="{}">
        <v-btn tile :color="variables.colorPrimary" dark class="mb-2 modal-cadastro-btn-cadastrar" @click="openModal">Cadastrar notificação</v-btn>
    </template>

    <v-card>
      <v-toolbar
        :color="variables.colorPrimary"
        flat
        dark
      >
        <v-toolbar-title>Cadastrar notificação</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-form>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                class="xxx"
                label="Tipos de usuários"
                menu-props="auto"
                v-model="item.tipoBusca"
                :items="listaTipoBusca"
                hide-details
                outlined
              ></v-select>
            </v-col>

            <v-col cols="3">
              <v-row>
                <v-autocomplete
                  label="Usúario(s)"
                  v-model="item.entityIds"
                  v-on:keyup="searchAutoComplete"
                  :items="listEntities"
                  :search-input.sync="searchText"
                  :loading="isLoadingAutoComplete"
                  :error-messages="errorsEntity"
                  multiple
                  outlined
                  clearable
                  chips
                  deletable-chips
                >
                <template v-slot:selection="{ items, index }">
                  <span
                    v-if="index === 0"
                    class="grey--text caption"
                  >
                  (+ {{ item.entityIds.length }} )
                  </span>
                </template>
                </v-autocomplete>
              </v-row>
            </v-col>

            <v-col cols="1">
              <v-checkbox
                v-model="selectAllUsers"
                label="Todos"
                class="v-checkbox"
                :color="variables.colorPrimary"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="4">
              <v-select
                label="Tipo de notificação"
                menu-props="auto"
                v-model="item.tipoNotificacao"
                :items="listaTipoNotificacao"
                :disabled="isIndividual()"
                hide-details
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="item.tipoNotificacao !== 'PUSH_NOTIF' ? 4 : 3">
              <v-text-field v-model="item.title" label="Titulo" :error-messages="errorsTitle"/>
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="item.tipoNotificacao !== 'PUSH_NOTIF' ? 4 : 6">
              <v-file-input v-if="item.tipoNotificacao !== 'PUSH_NOTIF'"
                accept="image/*"
                label="Imagem"
                prepend-icon="mdi-camera"
                v-model="image"
                :error-messages="errorsImage"
              ></v-file-input>

              <v-row v-else>
                <v-text-field v-model="item.icon" label="Icone URL" :error-messages="errorsIcon" class="margin-right"/>
                <v-text-field v-model="item.image" label="Imagem URL" :error-messages="errorsImage"/>
              </v-row>
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="item.tipoNotificacao !== 'PUSH_NOTIF' ? 4 : 3">
              <v-text-field v-model="item.link" label="Link" :error-messages="errorsLink"/>
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-textarea
                counter
                label="Descrição"
                v-model="item.description"
                :error-messages="errorsDescription"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn v-if="permiteAcao($route, 'add')" type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn">Enviar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { required, requiredIf } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import * as convertCvs from 'papaparse'
import { map, size } from 'lodash'
// import { validate } from 'gerador-validador-cpf'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
export default {
  name: 'ModalSmsMensagem',
  data: () => ({
    activeModal: false,
    form: {},
    file: null,
    msgAcentuada: false,
    tipoBusca: 6,
    tipoNotificacao: null,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ],
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    firstSearch: true,
    image: [],
    debounce: null,
    selectAllUsers: false
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('notificacao', ['itensSelectEntities', 'item', 'itensEntities']),
    variables: () => variables,
    errorsTitle () {
      const errors = []
      if (!this.$v.item.title.$dirty) return errors
      !this.$v.item.title.required && errors.push('Digite o titulo da notificação')
      if (errors.length === 0) return ''
      return errors
    },

    errorsImage () {
      const errors = []
      if (!this.$v.item.image.$dirty) return errors
      !this.$v.item.image.required && errors.push('Selecione uma imagem')
      if (errors.length === 0) return ''
      return errors
    },
    errorsIcon () {
      const errors = []
      if (!this.$v.item.icon.$dirty) return errors
      !this.$v.item.icon.required && errors.push('Selecione um ícone')
      if (errors.length === 0) return ''
      return errors
    },
    errorsLink () {
      const errors = []
      if (!this.$v.item.link.$dirty) return errors
      !this.$v.item.link.required && errors.push('Digite o link')
      // if (size(this.numeros) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    },
    errorsDescription () {
      const errors = []
      if (!this.$v.item.description.$dirty) return errors
      !this.$v.item.description.required && errors.push('Digite a descrição')
      // if (size(this.numeros) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    },

    errorsEntity () {
      const errors = []
      if (!this.$v.item.entityIds.$dirty) return errors
      !this.$v.item.entityIds.required && errors.push('Obrigatório a selecionar pelo menos um contato')
      // if (size(this.numeros) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    },

    listaTipoNotificacao () {
      return [
        {text: 'Whatsapp', value: 'WHATSAPP' },
        {text: 'Plataforma Individual', value: 'PORTAL-IND' },
        {text: 'Plataforma', value: 'PORTAL' },
        {text: 'Push Notification', value: 'PUSH_NOTIF' },
        {text: 'Modal PopUp', value: 'POPUPNOTIF' },
      ]
    },

    listEntities () {
      if (this.item.tipoNotificacao === 'PUSH_NOTIF') {
        return this.itensEntities
          .filter( item => item.firebaseToken ? item.firebaseToken.length > 0 : false )
          .map(item => ({
            text: item.name + (item.document ? ` (${item.document})` : ''),
            value: item.id
          })
        )
      }

      return this.itensSelectEntities
    }
  },
  mounted () {
    Events.$on('Daodos::modal::sms', () => { this.activeModal = true })
    this.filtroEntities()
  },
  watch: {
    'item.tipoBusca' (v) {
      this.item.entityIds = []
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    file (val) {
      this.convertCvs(val)
    },
    'image' (val) {
      this.addImage(val)
    },
    'selectAllUsers' (val) {
      if (val) this.item.entityIds = this.itensSelectEntities
      else if(this.item.entityIds.length > 0) this.item.entityIds = []
    },
    'item.entityIds' (val) {
      if (val.length <= 0) this.selectAllUsers = false
    }
  },
  methods: {
    ...mapActions('notificacao', ['sendSms', 'addFiltroAtual', 'addDadosTable', 'cadastrarItem', 'getItens']),
    ...mapActions('notificacao', { getItensEntities: 'obterItens' }),
    convertCvs (val) {
      // var lines= val.split("\n");
      // eslint-disable-next-line
      const vm = this
      let arquivo = convertCvs.parse(val, {
        config: {
          header: true,
          encoding: "utf-8"
        },
        complete: (results) => {
          // eslint-disable-next-line
          vm.clear()
          setTimeout(() => {
            let dados = vm.formatCvsFile(results)
            vm.addDadosTable(dados)
          }, 500)
        }
      })
      console.log(arquivo)
    },
    openModal () { this.activeModal = true },

    filtroEntities () {
      const vm = this
      this.getItensEntities({
        pageSize: 999999,
        _filter: { "entityTypeId_In":`${this.item.tipoBusca}`}
      }).then(() => {
        vm.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatCvsFile ({data}) {
      if (size(data) < 1) return false
      let array = []
      // chaves no header do arquivo
      let keys  = data[0]
      // eslint-disable-next-line
      let dados = map(data, (v, k) => {
        if (k > 0) { // pula a a posição 0 que é o as chaves do arquivo
          let obj = {}
          v.forEach((value, chave) =>{
            obj[keys[chave]] = value
          })
          array.push(obj)   
        }
      })
      return array
    },
    clear() {
      Events.$emit('sms:clearTable')
    },
    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()
        return false
      }

      let dados = {
        jsonData: {
          link: this.item.link,
          description: this.item.description,
          title: this.item.title,
          image: this.item.image,
          icon: this.item.icon
        },
        channelId: this.item.tipoNotificacao,
        selectPopup: this.item.selectPopup,
        entityId: this.item.entityIds.length > 0 ? this.item.entityIds : undefined
      }
      this.cadastrarItem([dados]).then(() => {
        successSnackbar('Mensagem cadastrada com sucesso!')
        this.closeModal()
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatDados () {
      let { form } = this
      let dados = this.numeros
      let text = String(form.message).split(' ')
      let regex = /[áàâãéèêíïóôõöúçñ/]/g
      let existeAcento = false
      
      text.forEach(nome => {
        let valido = nome.split(/ +/).every(parte => regex.test(parte))
        if (valido) { existeAcento = true }
      })

      if (size(dados)=== 0) return false
      // formata dados que vem da tabela ou por arquivo cvs, nunca dos dois
      dados = map(dados, v => ({
        number: 55 + String(v.numero || (`${v.ddd}${v.cellphone}`)).replace(/\s/g, ''),
        msg: form.message, code: existeAcento ? 8 : 0
      }))

      return dados
    },
    closeModal () {
      this.getItens()
      this.activeModal = false
    },

    searchAutoComplete() {
      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.search('')
          return
        }

      }, 600)
    },

    search (val) {
      this.isLoadingAutoComplete = true
      // this.obterItensSelect({ _filter: { "Entities:jsonData.name_ilike":`%${val}%` }, pageSize: this.$store.getters.ItensPaginacao })
      //   .catch(err => {
      //     errorSnackbar(err.error)
      //   })
      //   .finally(() => this.isLoadingAutoComplete = false)

      let typeFilter = null

      if (!isNaN(val.replace(/\D/g, '')) && this.item.tipoBusca === 6) {
        typeFilter = { "Entities:jsonData.cpf_ilike":`%${val.replace(/\D/g, '')}%` }
      } else if (!isNaN(val.replace(/\D/g, ''))) {
        typeFilter = { "Entities:jsonData.cnpj_ilike":`%${val.replace(/\D/g, '')}%` }
      } else {
        typeFilter = { "Entities:jsonData.name_ilike":`%${val}%` }
      }

      const _filter = { "entityTypeId_In":`${this.item.tipoBusca}`, ...typeFilter }

      this.getItensEntities({
        pageSize: 999999,
        _filter
      }).then(() => {
        this.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
      .finally(() => this.isLoadingAutoComplete = false)
    },

    isIndividual () {
      if (this.item.entityIds.length > 0 && this.item.tipoNotificacao !== 'PUSH_NOTIF') {
        this.item.tipoNotificacao = 'PORTAL-IND'
        return true
      }
      return false
    },

    addImage (e) {
      if (!e) {
        this.item.image = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.image = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.image = e.target.result;
      reader.readAsDataURL(e);
    }
  },
  validations () {
    return {
      item: {
        icon: { required: requiredIf(() => this.item.tipoNotificacao === 'PUSH_NOTIF') },
        image: { required },
        link: { required },
        title: { required },
        description: { required },

        entityIds: {
          required: requiredIf(() => this.item.tipoNotificacao === 'PORTAL-IND')
        }
        
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../../assets/styles/components/modal-cadastro';

.v-toolbar {
  margin: 16px;
}

.v-select__selections {
  height: 74px;
}

.margin-right {
  margin-right: 16px;
}
</style>
